<template>
    <div
        @mouseover="show = true"
        @mouseleave="device.size > 768 ? (show = false) : ''"
        :id="`main${itemId}`"
        class="gallery-image unselectable"
    >
        <div @click="clickHandler" class="cover-container unselectable">
            <mouse-changer :type="mouseTypes.ImageScale">
                <img
                    :src="item.cover_image"
                    id="cover_image"
                    class="image unselectable"
                    alt=""
                />
            </mouse-changer>
            <div :class="{ active: show }" class="hidden-content">
                <div class="content-container">
                    <div class="title">{{ item.title }}</div>
                    <div class="down">
                        <span class="text">{{ item.date }}</span>
                        <div class="line" v-if="item.title"></div>
                        <span class="text"
                            >{{ item.length }} {{ _tr("ფოტო") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <portal to="destination">
            <div
                v-if="showGallery"
                :id="itemId"
                class="gallery-image-scale-container image-scale-container"
            >
                <mouse-changer :type="mouseTypes.ImageDown">
                    <div
                        id="scale-container-gallery"
                        @click="scaleDown"
                        class="scale-container-gallery"
                    >
                        <div
                            v-for="(image, index) in item.images"
                            :key="index"
                            :class="{ active: activeImageId === image.id }"
                            class="scale-image-container"
                        >
                            <img
                                :src="image.path"
                                :style="index === 0 ? clickedElementStyles : ''"
                                class="image unselectable"
                                alt="image.path"
                            />
                            <div class="content unselectable">
                                <header-title
                                    :name="item.title"
                                    text-color="white"
                                />
                                <!--          <h2 class="position">{{ scaleContent.position }}</h2>-->
                                <!--          <h2 class="place" v-html="scaleContent.place"></h2>-->
                            </div>
                        </div>
                    </div>
                </mouse-changer>
                <div class="slide-switcher">
                    <div
                        v-for="(image, index) in item.images"
                        @click="activeImageId = image.id"
                        :key="index"
                        class="item"
                    >
                        <img
                            :src="image.path"
                            :class="{ active: activeImageId === image.id }"
                            class="image"
                            alt="image.path"
                        />
                    </div>
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
import HeaderTitle from "@/components/custom/HeaderTitle";
import { mapGetters, mapActions } from "vuex";
import first from "lodash/first";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        id: {
            type: [Number, Object],
            default: 1
        }
    },
    data() {
        return {
            show: false,
            showGallery: false,
            clickedItemPosition: {},
            activeImageId: 0
        };
    },
    components: {
        HeaderTitle,
        MouseChanger
    },
    computed: {
        ...mapGetters({
            scroll: "scroll/getScroll",
            device: "resize/geDevice",
            dragging: "drag/getDragging"
        }),
        clickedElementStyles() {
            return {
                top: `${this.clickedItemPosition?.top}px`,
                width: `${this.clickedItemPosition?.width}px`,
                height: `${this.clickedItemPosition?.height}px`,
                right: `${this.clickedItemPosition?.right}px`,
                transform: `translate(${this.padding}px,-${this.padding}px)`,
                left: "unset",
                position: "absolute"
            };
        },
        innerWidth() {
            return this.device.size;
        },
        itemId() {
            return `gallery${this.id}`;
        },
        padding() {
            let padding = 50;

            if (this.device.size < 1651) {
                padding = 30;
            }
            if (this.device.size < 1366) {
                padding = 20;
            }
            if (this.device.size < 768) {
                padding = 0;
            }
            return padding;
        }
    },
    watch: {
        showGallery(val) {
            if (!val) this.setMouseType("Default");
        },

        device: {
            immediate: true,
            handler(val) {
                if (val.size < 768) {
                    this.show = true;
                }
            }
        }
    },
    methods: {
        ...mapActions({
            setMouseType: "mouseCursor/setMouseType"
        }),
        hexToRgbA(hex, opacity = 0.7) {
            if (!hex) return "rgba(1, 1, 1, 1)";
            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                return (
                    "rgba(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    "," +
                    opacity +
                    ")"
                );
            }
            throw new Error("Bad Hex");
        },
        mouseover() {},
        mouseleave() {},
        initClickedItemAnimation({ right, top, width, height }) {
            let newWidth = innerWidth - 2 * this.padding;
            let aspectRatio = height / width;
            let newHeight = newWidth * aspectRatio;
            let scaleX = newWidth / width;
            let scaleY = newHeight / height;
            let transformX = -(
                innerWidth -
                (right + width + (newWidth - width) / 2) -
                2 * this.padding
            );
            let transformY =
                newHeight / 2 -
                top -
                height / 2 -
                this.padding +
                (innerHeight - newHeight) / 2;

            if (this.device.size < 1024) {
                aspectRatio = width / height;
                newHeight = this.device.innerHeight - 2 * this.padding;
                newWidth = aspectRatio * newHeight;
                transformX =
                    -(
                        innerWidth -
                        (right + width + (newWidth - width) / 2) -
                        this.padding
                    ) -
                    (newWidth - innerWidth) / 2;
                transformY = newHeight / 2 - top - height / 2;
                scaleX = newWidth / width;
                scaleY = newHeight / height;
            }

            this.gsap.set(`#${this.itemId}.image-scale-container`, {
                opacity: 1
            });

            let image = document.querySelectorAll(
                `#${this.itemId} #scale-container-gallery img`
            )[0];

            this.gsap.to(image, {
                duration: 1,
                clipPath: "inset(0px)",
                force3D: false,
                x: transformX,
                yPercent: 0,
                y: transformY,
                scaleX: scaleX,
                scaleY: scaleY,
                ease: this.CustomEase.create(
                    "custom",
                    "M0,0,C0.001,0,-0.001,0,0,0,0.798,0,0.205,1,1,1"
                ),
                onComplete: () => {
                    this.gsap.to(`#${this.itemId} #scale-container-gallery`, {
                        background: this.item.color,
                        pointerEvents: "auto"
                    });
                    this.gsap.to(`#${this.itemId}  .slide-switcher`, {
                        opacity: 1,
                        pointerEvents: "auto"
                    });
                    this.animated = false;
                }
            });
        },
        setClonePosition() {
            let item = document.querySelector(
                `#main${this.itemId} #cover_image`
            );
            let { width, height, right, top } = item.getBoundingClientRect();
            this.clickedItemPosition = {
                top,
                right: this.innerWidth - right,
                width,
                height
            };
        },
        clickHandler() {
            if (this.dragging) return;
            this.showGallery = true;
            this.setClonePosition();
            this.scroll?.stop();
            this.show = false;
            this.animated = true;
            let { right, height, top, width } = this.clickedItemPosition;

            setTimeout(() => {
                this.initClickedItemAnimation({ right, top, width, height });
            }, 400);
        },
        scaleDown() {
            this.show = true;
            this.scroll?.start();
            this.activeImageId = first(this.item?.images)?.id;
            this.gsap.to(`#${this.itemId}.image-scale-container`, {
                opacity: 0,
                onComplete: () => {
                    this.gsap.set(`#${this.itemId} #scale-container-gallery`, {
                        background: "transparent",
                        pointerEvents: "none"
                    });
                    this.gsap.set(`#${this.itemId}  .slide-switcher`, {
                        opacity: 0,
                        pointerEvents: "none"
                    });
                    this.showGallery = false;
                }
            });

            let image = document.querySelectorAll(
                `#${this.itemId} #scale-container-gallery img`
            )[0];
            this.gsap.set(image, {
                x: this.padding,
                y: -this.padding,
                scaleX: 1,
                scaleY: 1
            });
            this.setClonePosition();
        },
        resize() {
            if (!this.showGallery) return;
            let { right, height, top, width } = this.clickedItemPosition;

            this.initClickedItemAnimation({ right, top, width, height });
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize);
        },
        mousemove(ev) {
            if (this.animated || this.device.size < 1025) return;
            const image = document.querySelector(
                ".gallery-image-scale-container .scale-image-container.active img"
            );
            let itemHeight = image?.getBoundingClientRect()?.height;

            if (!image || itemHeight < innerHeight - 2 * this.padding) return;

            const clamp = (num, min, max) => {
                return num < min ? min : num > max ? max : num;
            };
            let maxDelta = (itemHeight - innerHeight) / 2;
            let a = innerHeight / 2 - ev.clientY;
            let del = (a * (innerHeight / 2 + maxDelta)) / (innerHeight / 2);
            let itemRealHeight = this.gsap.getProperty(image, "height");
            let itemTop = this.gsap.getProperty(image, "top");
            let itemBottom = innerHeight - itemTop - itemRealHeight;
            let topMaxDelta =
                parseInt(itemHeight) === parseInt(itemRealHeight)
                    ? -itemTop
                    : (itemHeight - itemRealHeight) / 2 - itemTop;
            let bottomMaxDelta = -(
                (itemHeight - itemRealHeight) / 2 -
                itemBottom +
                2 * this.padding
            );
            this.gsap.to(image, {
                yPercent: 0,
                y: clamp(del, bottomMaxDelta, topMaxDelta)
            });
        },
        mouseMoveHandler() {
            if (this.device.size < 1025) return;
            window.addEventListener("mousemove", this.mousemove, false);
        }
    },
    mounted() {
        this.resizeHandler();
        this.mouseMoveHandler();
        this.activeImageId = first(this.item?.images)?.id;
    },
    beforeDestroy() {
        window.removeEventListener("mousemove", this.mousemove);
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.gallery-image /deep/ {
    .cover-container {
        position: relative;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .unselectable {
        outline: none;
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .mouse-changer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image {
        width: 100%;
        pointer-events: none;
    }

    .hidden-content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.464) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        opacity: 0;
        transition: all 0.4s 0.4s $ease-out;

        &.active {
            opacity: 1;
            transition: all 0.4s $ease-out;

            .content-container {
                .title {
                    opacity: 1;
                    transform: translateY(0);
                    transition: all 0.4s 0.4s $ease-out;
                }

                .text {
                    opacity: 1 !important;
                    transform: translateX(0) !important;
                }

                .line {
                    opacity: 1 !important;
                    transform: translateY(0) !important;
                    transition: all 0.4s 0.4s $ease-out !important;
                }

                .text {
                    &:nth-child(1) {
                        transition: all 0.4s 0.45s $ease-out !important;
                    }

                    &:nth-child(3) {
                        transition: all 0.4s 0.6s $ease-out !important;
                    }
                }
            }
        }

        .content-container {
            position: absolute;
            bottom: 10%;
            width: calc(100% - 60px);
            transform: translateX(-50%);
            left: 50%;
            color: white;
            text-align: left;

            .title {
                font-size: 21px;
                transform: translateY(20px);
                opacity: 0;
                transition: all 0.4s $ease-out;
            }

            .down {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .text {
                    font-size: 16px;
                    transform: translateX(20px);
                    transition: all 0.4s $ease-out;
                    display: block;
                    opacity: 0;
                }

                .line {
                    width: 2px;
                    height: 11px;
                    background: #e5b676;
                    margin: 0 10px;
                    opacity: 0;
                    transition: all 0.4s $ease-out;
                }
            }
        }
    }
}

.gallery-image-scale-container /deep/ {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 99;

    .mouse-changer {
        height: 100%;
        width: 100%;
        z-index: 10;
        overflow: hidden;
    }

    .scale-container-gallery {
        height: 100%;
        width: 100%;
        z-index: 10;
        pointer-events: none;
        overflow: hidden;
        @media only screen and (max-width: 1023px) {
            position: fixed;
            z-index: 99;
        }

        .scale-image-container {
            position: absolute;
            height: calc(100vh - 100px);
            width: calc(100% - 100px);
            overflow: hidden;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 0.4s $ease-out;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                height: calc(100vh - 60px);
                width: calc(100% - 60px);
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                height: calc(100vh - 40px);
                width: calc(100% - 40px);
            }
            @media only screen and (max-width: 767px) {
                height: 100vh;
                width: 100%;
            }

            &.active {
                opacity: 1;
                transition: opacity 0.4s 0.3s $ease-out;
            }

            .image {
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                left: 0;
                @media only screen and (max-width: 768px) {
                    width: auto;
                    height: 100%;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .content {
        position: absolute;
        left: 120px;
        bottom: 137px;
        opacity: 0;
        text-align: left;
        z-index: 1;
        @media only screen and (max-width: 1440px) and (min-width: 1025px) {
            left: 100px;
        }

        @media only screen and (max-width: 1024px) and (min-width: 769px) {
            left: 70px;
            bottom: 95px;
        }

        @media only screen and (max-width: 768px) {
            left: 40px;
            bottom: 70px;
        }

        .name {
            font-size: 55px;
            color: white;
            text-decoration: underline;
            text-decoration-color: #e5b676;
            text-decoration-thickness: 2px;
        }

        .position {
            font-size: 21px;
            color: #e5b676;
            margin-top: 16px;
        }

        .place {
            font-size: 16px;
            color: white;
            margin-top: 6px;
            max-width: 600px;
        }
    }

    .slide-switcher {
        position: absolute;
        display: flex;
        flex-direction: column;
        grid-gap: 42px;
        background: rgba(10, 20, 28, 0.75);
        padding: 30px;
        width: fit-content;
        z-index: 999;
        opacity: 0;
        overflow: overlay;
        height: calc(100vh - 100px);
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        @media only screen and (max-width: 1023px) {
            position: fixed;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #e5b676;
        }

        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
            height: calc(100vh - 60px);
            right: 30px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            height: calc(100vh - 40px);
            right: 20px;
        }
        @media only screen and (max-width: 767px) {
            grid-gap: 10px;
            padding: 10px;
            height: 100vh;
            right: 0;
        }

        .image {
            width: 100px;
            height: 60px;
            object-fit: cover;
            cursor: pointer;
            opacity: 0.3;
            border: 2px solid transparent;
            @media only screen and (max-width: 768px) {
                width: 80px;
                height: 55px;
            }

            &.active {
                opacity: 1;
                border: 2px solid white;
            }
        }
    }
}
</style>
