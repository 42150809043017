<template>
    <div :class="{ reverse, dark: !lightMode }" class="news">
        <div @click="routeChange" class="image-container">
            <img
                data-scroll
                :data-scroll-id="imageParalax"
                :src="item.path"
                class="image"
                alt=""
            />
            <div class="line"></div>
        </div>
        <div class="mobile-line"></div>
        <div class="content-container">
            <div
                v-line-clamp="2"
                :title="item.header"
                class="header-title-news"
            >
                {{ item.header }}
            </div>
            <span class="date">{{ date }}</span>
            <div @click="routeChange" class="explore">
                <svg
                    class="icon"
                    width="22.23"
                    height="11.775"
                    viewBox="0 0 22.23 11.775"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(21.523 0.707) rotate(90)"
                    >
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            x2="5.192"
                            y2="5.431"
                            transform="translate(5.17 0)"
                            fill="none"
                            stroke="#0a141c"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                        <line
                            id="Line_4"
                            data-name="Line 4"
                            x1="5.17"
                            y2="5.431"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#0a141c"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            y2="20.265"
                            transform="translate(5.181 0.759)"
                            fill="none"
                            stroke="#0a141c"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                    </g>
                </svg>
                <span class="title">Explore</span>
            </div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
        lightMode: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            lang: "language/getLang",
            device: "resize/geDevice"
        }),
        reverse() {
            return this.index % 2 === 1;
        },
        date() {
            return moment(this.item.date).format("DD MMM, YYYY");
        },
        imageParalax() {
            return this.device.size < 1024 ? "" : `imageParallax${this.index}`;
        }
    },
    methods: {
        routeChange() {
            this.$router
                .push({
                    path: `/${this.lang}/media-events/news/singleview/${this.item?.id}/${this.item?.slug}`
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.news {
    position: relative;
    width: 100%;

    &.reverse {
        align-self: flex-end;

        .image-container {
            .line {
                left: unset;
                right: -30px;
                @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                    right: -20px;
                }
                @media only screen and (max-width: 1024px) and (min-width: 768px) {
                    right: -15px;
                }
                @media only screen and (max-width: 767px) {
                    right: -5px;
                }
            }
        }

        .content-container {
            right: unset;
            left: 0;
            padding: 60px 60px 0 0;

            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                padding: 40px 40px 0 0;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                padding: 30px 25px 0 0;
            }
            @media only screen and (max-width: 767px) {
                padding: 0;
            }

            .line {
                left: unset;
                right: 0;
            }
        }
    }

    &.dark {
        .content-container {
            background: #0a141c;

            .header-title-news,
            .explore .title {
                color: white;
            }

            .explore {
                svg {
                    line {
                        stroke: white;
                    }
                }
            }
        }
    }

    .image-container {
        width: 1020px;
        height: 574px;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
            width: 635px;
            height: 357px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            width: 530px;
            height: 298px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 445px;
            height: 251px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            height: 215px;
            position: relative;
        }

        &:hover {
            img {
                filter: brightness(95%) contrast(100%) saturate(0%)
                    brightness(100%);
                @media only screen and (max-width: 1023px) {
                    filter: unset;
                }
            }
        }

        img {
            width: 105%;
            height: 100%;
            object-fit: cover;
            transform-origin: bottom;
            transition: filter 0.6s $ease-out;
        }

        .line {
            height: 1px;
            width: 100%;
            left: -30px;
            position: absolute;
            bottom: 0;
            background: #e5b676;
            transform: translateY(50%);
            will-change: transform;
            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                left: -10px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                left: -10px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }

    .mobile-line {
        height: 1px;
        background: #e5b676;
        width: calc(100% + 20px);
        left: -10px;
        position: relative;
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }

    .content-container {
        background: white;
        padding: 60px 0 0 60px;
        width: 750px;
        position: absolute;
        height: 264px;
        bottom: -98px;
        right: 0;
        text-align: left;
        transition: all 0.6s $ease-out;
        will-change: transform;
        @media only screen and (max-width: 1500px) and (min-width: 1366px) {
            width: 635px;
            height: 234px;
            padding: 30px 0 0 30px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            min-width: 530px;
            height: 196px;
            padding: 30px 0 0 30px;
            width: 59%;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 445px;
            height: 196px;
            padding: 30px 0 0 30px;
        }
        @media only screen and (max-width: 767px) {
            position: relative;
            right: unset;
            padding: 0;
            min-width: unset;
            width: 100%;
            height: unset;
            left: unset !important;
            bottom: unset !important;
            transform: translateY(0);
            margin-top: 21px;
        }

        .header-title-news {
            color: #0a141c;
            font-weight: normal;
            font-size: 34px;
            line-height: 60px;
            display: block;
            will-change: transform;
            word-break: keep-all !important;

            @media only screen and (max-width: 1365px) {
                font-size: 21px;
                line-height: 40px;
            }
        }

        .date {
            margin: 10px 0 30px;
            color: #e5b676;
            display: block;
            font-size: 16px;
            will-change: transform;

            @media only screen and (max-width: 767px) {
                margin: 10px 0 20px;
            }
        }

        .explore {
            display: flex;
            align-items: center;
            cursor: pointer;
            will-change: transform;

            .title {
                color: #0a141c;
                font-size: 16px;
                letter-spacing: 0.2em;
                line-height: 16px;
                display: block;
                will-change: transform;
                margin-left: 15px;
            }
        }

        .line {
            position: absolute;
            will-change: transform;
            height: 1px;
            background: #e5b676;
            left: 0;
            width: 30px;
            bottom: 98px;
            transform: translateY(50%);
            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                width: 20px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                width: 15px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}
</style>
