<template>
    <div class="news-item">
        <div class="header">
            <div class="bottom-line"></div>
        </div>
        <div class="item">
            <h2 class="header-title">{{ item.title }}</h2>
            <span class="date">{{ item.date }}</span>
            <div class="image-container">
                <div
                    v-if="item.videoSource || item.video"
                    class="video-container"
                >
                    <iframe
                        :src="item.videoSource"
                        v-if="item.videoSource"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                    <mouse-changer v-else :type="mouseTypes.VideoScale">
                        <video
                            muted
                            data-scroll
                            data-scroll-id="inside-scale1"
                            autoplay
                            @click="showVideo(item.video)"
                            loop
                            playsinline
                            :poster="item.image"
                        >
                            <source :src="item.video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </mouse-changer>
                </div>
                <mouse-changer v-else :type="mouseTypes.BlendMode">
                    <img
                        data-scroll
                        data-scroll-id="inside-scale"
                        :src="item.image"
                        class="image"
                        alt=""
                    />
                </mouse-changer>
            </div>
            <div class="description" v-html="item.content"></div>
        </div>
        <div class="image-gallery" v-if="images.length">
            <h2 class="header-title">{{ _tr("Gallery") }}</h2>
            <div id="draggable" class="gallery-list draggable">
                <div
                    id="draggable-slider"
                    class="draggable-slider unselectable"
                >
                    <image-gallery-item
                        v-for="item in images"
                        :key="item.id"
                        :id="item.id"
                        class="slide-item"
                        :item="item"
                    />
                </div>
            </div>
        </div>
        <ShareNetwork
            network="facebook"
            :url="shareUrl"
            :title="item.title"
            :description="item.content"
            :quote="item.title"
        >
            <div class="share-container">
                <div class="content">
                    <span class="title">{{ _tr("Share News") }}</span>
                    <img
                        src="../../assets/images/share.svg"
                        class="icon"
                        alt=""
                    />
                </div>
            </div>
        </ShareNetwork>
        <div class="similar-news">
            <header-title :name="_tr('Similar News')" text-color="#0A141C" />
            <news-item :item="relationItem" :index="0" />
        </div>
    </div>
</template>

<script>
import first from "lodash/first";
import ImageGalleryItem from "@/components/custom/ImageGalleryItem";
import { mapActions, mapGetters } from "vuex";
import drag from "@/scripts/draggableSlider";
import HeaderTitle from "@/components/custom/HeaderTitle";
import NewsItem from "@/components/PageComponents/NewsItem";
import imagesLoaded from "imagesloaded";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: [Object, Array],
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        NewsItem,
        HeaderTitle,
        ImageGalleryItem,
        MouseChanger
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        shareUrl() {
            return window.location.href;
        },
        item() {
            return first(
                this.data?.list?.map(item => ({
                    ...item,
                    image: item.cover_image?.[0]?.devices[this.device?.type],
                    videoSource: item?.video?.source,
                    video: item?.video?.videoFile?.url
                }))
            );
        },
        allImages() {
            return this?.item?.gallery?.map(image => ({
                path: image?.devices?.[this.device?.type],
                id: image.id
            }));
        },
        images() {
            return this?.item?.gallery?.map(image => {
                let item = this.allImages?.find(item => item.id === image.id);
                let filterData = this.allImages.filter(
                    item => item.id !== image.id
                );
                filterData.unshift(item);

                return {
                    cover_image: image?.devices?.[this.device?.type],
                    id: image.id,
                    images: filterData,
                    color: this.item.frame_color,
                    title: image.title?.title
                };
            });
        },
        relationItem() {
            let item = first(first(this.data?.list)?.relations_data?.News);
            return {
                header: item?.title,
                date: item?.date,
                path: item?.cover_image?.[0]?.devices?.[this.device?.type],
                id: item?.id
            };
        }
    },
    methods: {
        ...mapActions({
            setContent: "video/setContent"
        }),
        getPadding(width) {
            let padding = 30;

            if (width <= 1024) {
                padding = 16;
            }
            return padding;
        },
        showVideo(path) {
            this.setContent({
                path
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            imagesLoaded(
                document.querySelector(".news-item .draggable-slider"),
                () => {
                    drag(
                        this.slideAnimation,
                        false,
                        true,
                        "draggable-slider",
                        this.getPadding
                    );
                }
            );
        });
    }
};
</script>

<style lang="scss" scoped>
.news-item /deep/ {
    .header {
        position: relative;
        background: #f3efe6;
        height: 233px;
        @media only screen and (max-width: 1440px) and (min-width: 1025px) {
            height: 210px;
        }
        @media only screen and (max-width: 1025px) and (min-width: 768px) {
            height: 170px;
        }
        @media only screen and (max-width: 767px) {
            height: 140px;
        }

        .bottom-line {
            position: absolute;
            bottom: 0;
            background: #e5b676;
            width: calc(100% - 150px);
            height: 1px;
            left: 50%;
            transform: translateX(-50%);

            @media only screen and (max-width: 1440px) and (min-width: 1025px) {
                width: calc(100% - 100px);
            }
            @media only screen and (max-width: 1025px) and (min-width: 769px) {
                width: calc(100% - 70px);
            }
            @media only screen and (max-width: 769px) {
                width: calc(100% - 20px);
            }
        }
    }

    .unselectable {
        outline: none;
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    a {
        text-decoration: none;
    }

    .item {
        position: relative;
        z-index: 1;
        margin: -191px 225px 0;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin: -151px 165px 0;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin: -151px 128px 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin: -141px 47px 0;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin: -140px 47px 0;
        }
        @media only screen and (max-width: 767px) {
            margin: -120px 16px 0;
        }

        .header-title {
            font-size: 34px;
            color: #0a141c;
            text-align: left;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                font-size: 28px;
                margin-top: 25px;
            }
            @media only screen and (max-width: 1024px) and (min-width: 768px) {
                font-size: 24px;
                margin-top: 20px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 21px;
                margin-top: 20px;
            }
        }

        .date {
            font-size: 16px;
            color: #e5b676;
            margin-top: 20px;
            display: block;
            text-align: left;
        }

        .image-container {
            margin-top: 34px;
            max-height: 800px;
            overflow: hidden;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                margin-top: 25px;
            }
            @media only screen and (max-width: 1023px) {
                margin-top: 20px;
            }

            .image,
            iframe,
            video {
                width: 100%;
            }

            .video-container {
                // --video--width: 1296;
                // --video--height: 540;

                position: relative;
                padding-bottom: calc(
                    var(--video--height) / var(--video--width) * 100%
                ); /* 41.66666667% */
                overflow: hidden;
                max-width: 100%;
                background: black;
            }

            .video-container iframe,
            .video-container object,
            .video-container embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .description {
            color: #0a141c;
            font-size: 16px;
            margin-top: 55px;
            text-align: left;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                margin-top: 35px;
            }
            @media only screen and (max-width: 1023px) {
                margin-top: 20px;
            }
        }
    }

    .image-gallery {
        margin-top: 56px;
        text-align: left;
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            margin-top: 40px;
        }
        @media only screen and (max-width: 1023px) {
            margin-top: 30px;
        }

        .header-title {
            color: #e5b676;
            font-size: 34px;
            margin: 0 225px;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                margin: 0 165px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                margin: 0 128px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                margin: 0 47px;
            }
            @media only screen and (max-width: 767px) {
                margin: 0 16px;
            }
        }

        .gallery-list {
            margin-top: 34px;
            padding: 0 30px;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                margin-top: 25px;
            }
            @media only screen and (max-width: 1023px) {
                margin-top: 20px;
                padding: 0 16px;
            }

            .draggable-slider {
                width: fit-content;
                display: flex;
                align-items: center;
                grid-gap: 32px;
            }

            .gallery-image {
                img {
                    height: 400px;
                    width: auto;
                    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                        height: 300px;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        height: 250px;
                    }
                    @media only screen and (max-width: 767px) {
                        height: 200px;
                    }
                }
            }
        }
    }

    .share-container {
        border-top: 1px solid #e5b676;
        padding-top: 30px;
        margin: 89px 75px 0;
        text-decoration: none;
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            margin: 70px 50px 0;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin: 60px 47px 0;
        }
        @media only screen and (max-width: 767px) {
            margin: 30px 16px 0;
        }

        .content {
            display: flex;
            align-items: center;
            margin: 0 150px;

            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                margin: 0 50px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                margin: 0 25px;
            }
            @media only screen and (max-width: 767px) {
                margin: 0 5px;
            }
        }

        .title {
            color: #e5b676;
            font-size: 16px;
            margin-right: 11px;
        }
    }

    .similar-news {
        margin: 144px 225px 124px;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin: 144px 165px 119px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin: 128px 144px 119px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin: 89px 47px 119px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin: 89px 47px 89px;
        }
        @media only screen and (max-width: 767px) {
            margin: 55px 16px 0;
        }

        .header-title {
            font-size: 46px;
            text-align: left;
            margin-bottom: 55px;

            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                font-size: 34px;
                margin-bottom: 34px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 21px;
                margin-bottom: 21px;
            }
        }
    }
}
</style>
